import React from 'react';
import { Row, Col, Button } from 'react-materialize';
import { useState } from 'react';

const BeMyGirlfriend = props => {
  const notWantLabelsArray = [
    '¿Te gustaría ser mi novia?',
    '¿Estas segura que no quieres xD?',
    '¿Estas super super SUPER segura que no?',
    'Piensalo bien amor xD, enserio no? Tengo muy buenos chistes',
    'A ver ver ver porque has precionado tantas veces no? anda, te voy a hacer reir muchas muchas veces, entonces ?',
    'Bueno bueno, ya no voy a cambiar el texto. Te gustaría ser mi novia? , would you like to be my girlfriend? , 내 여자 친구가되어 줄래?',
  ];

  const yesWantLabelsArray = [
    'SIIIIII, muchas gracias, ya planee nuestra boda y todo xD, gracias por decir que SI, soy el hombre mas afortunado del mundo :) <3 TE AMOO',
  ];

  const [indexNoWant, setIndexNoWant] = useState(0);
  const [indexYesWant, setIndexYesWant] = useState(null);

  const label = () => {
    if (indexYesWant != null) {
      return yesWantLabelsArray[indexYesWant];
    } else {
      return notWantLabelsArray[indexNoWant];
    }
  };

  return (
    <div className="section white marginTop30 marginBottom30">
      <div className="row container">
        <h5 className="header center">
          Hola mi amorch, me has dicho muchas y muchas veces que nunca te pedi
          ser mi novia: Bueno...
        </h5>
        <h3 className="header center">{label()}</h3>
        <Row>
          <Col s={3}></Col>
          <Col s={3}>
            <Button
              node="button"
              style={{
                marginRight: '5px',
              }}
              waves="light"
              onClick={() => {
                setIndexYesWant(0);
              }}
            >
              YES
            </Button>
          </Col>
          <Col s={3}>
            <Button
              node="button"
              style={{
                marginRight: '5px',
              }}
              waves="light"
              onClick={() => {
                if (indexNoWant >= notWantLabelsArray.length - 1) {
                  setIndexYesWant(0);
                } else {
                  setIndexNoWant(indexNoWant + 1);
                }
              }}
            >
              {indexNoWant >= notWantLabelsArray.length - 1 ? 'YES' : 'NO'}
            </Button>
          </Col>
          <Col s={1}></Col>
        </Row>
      </div>
    </div>
  );
};

export default BeMyGirlfriend;
